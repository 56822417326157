import React, { Component } from 'react'

import AliceCarousel from 'react-alice-carousel'
import img1 from './img/logo_sd.png'
import img2 from './img/logo_k31.png'
import img3 from './img/logo_gp.png'
import img4 from './img/logo_pet.png'
import img5 from './img/logo_alfa.png'

export class HomeThumbCarusel extends Component {
    items = [
        { "id": 1, "title":"Семейный доктор", "img": img1},
        { "id": 2, "title":"Клиника К+31","img": img2},
        { "id": 3, "title":"ДжиПи Фарма","img": img3},
        { "id": 4, "title":"ПЭТ Технолоджи","img": img4},
        { "id": 5, "title":"Альфа Центр Здоровья","img": img5},
        
    ]
     state = {
    galleryItems: this.items.map((i) => (
    <div className="text-center mt-5 mb-5"> 
      <img src={i.img}  key={i.id} alt="{i.title}" /> 
      {/* <h4 className="display-4">{i.title}</h4> */}
    </div>
    ))
  }
      responsive = { 0: { items: 1 }, 1024: { items: 4 },}

      onSlideChange(e) {
        console.debug('Item`s position during a change: ', e.item)
        console.debug('Slide`s position during a change: ', e.slide)
      }
     
      onSlideChanged(e) {
        console.debug('Item`s position after changes: ', e.item)
        console.debug('Slide`s position after changes: ', e.slide)
      }

    render() {
        
    
        return (
<React.Fragment>
          
            <AliceCarousel
            items={this.state.galleryItems}
            responsive={this.responsive}
            dotsDisabled={true}
            buttonsDisabled={true}
            autoPlayInterval={2000}
            autoPlayDirection="rtl"
            autoPlay={true}
            fadeOutAnimation={true}
            mouseDragEnabled={true}
            playButtonEnabled={false}
            disableAutoPlayOnAction={true}
            onSlideChange={this.onSlideChange}
            onSlideChanged={this.onSlideChanged}
          />    
</React.Fragment>
        )
    }
}

export default HomeThumbCarusel
