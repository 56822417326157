import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

export class NotFound extends Component {
    render() {
        return (
            <Container >
            <Row className="text-center" >
              <Col >
              <h1 className="display-1">404</h1>
              <p>Видимо этой страницы не существует!</p>
              <p><NavLink to="/">на главную</NavLink></p>
              
              </Col>
            </Row>
          </Container>
        )
    }
}

export default NotFound
