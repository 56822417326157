import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'





export class MenuLinks extends Component {
    render() {
        const { t } = this.props;
        return (
    
            <div>
                <ul className="navbar-nav">
                    <li className="nav-item"  > 
                        <NavLink exact activeClassName="active_mm"  className="nav-link" to="/" >
                            <div className="display-5 mm">{t('PageTitle.HomeTitle')}</div>
                            <small className="mm_des">{t('PageTitle.HomeTitleShortDesc')}</small>
                            </NavLink>
                    </li>
                    <li className="nav-item"> 
                        <NavLink activeClassName="active_mm"   className="nav-link" to="/project" >
                        <div className="display-5 mm">{t('PageTitle.ProgectTitle')}</div>
                        <small className="mm_des" >{t('PageTitle.ProgectTitleShortDesc')}</small>
                        </NavLink>
                    </li>
                    <li className="nav-item"> 
                        <NavLink   activeClassName="active_mm" className="nav-link" to="/connection" >
                        <div className="display-5 mm">{t('PageTitle.ConnectionTitle')}</div>
                        <small className="mm_des">{t('PageTitle.ConnectionTitleShortDesc')}</small>
                            
                            </NavLink>
                    </li>
                    {/* <li className="nav-item"> 
                        <NavLink activeClassName="active_mm" className="nav-link" to="/documentation" >
                        <div className="display-5 mm">{t('PageTitle.DocumentationTitle')}</div>
                        <small className="mm_des">{t('PageTitle.DocumentationShortDesc')}</small>
                            </NavLink>
                    </li> */}



                    <li className="nav-item"> 
                        <NavLink activeClassName="active_mm" className="nav-link" to="/contacts" >
                        <div className="display-5 mm">{t('PageTitle.ContactTitle')}</div>
                        <small className="mm_des">{t('PageTitle.ContactTitleShortDesc')}</small>
                            </NavLink>
                    </li>

                </ul>
            </div>
        )
    }
}
    
export default withTranslation()(MenuLinks)
