import React, { Component } from 'react'
import { Media, Badge } from 'react-bootstrap' 
import API from '../../utils/api';



export class NewsTeaser extends Component {
    
    constructor(props, context){
        super(props, context);
        this.state = {
        show: false,
          items: [],
          hasErrored: null,
          isLoading: false
        };
      
        this.handleShow = () => {
            this.setState({ show: true });
          };
      
          this.handleHide = () => {
            this.setState({ show: false });
          };
    }
     
    
      componentDidMount() {
        this.setState({ isLoading: true });
        
        API.get(`/news.json`)
          .then(result => this.setState({
            items: result.data,
            isLoading: false
          }))
          .catch(hasErrored => this.setState({
            hasErrored,
            isLoading: false
          }));
        }

    render() {
        if (this.state.hasErrored) {
            return <span>Упс! Что то пошло не так</span>;
          }
    
          if (this.state.isLoading) {
            return <span>Идет загрузка…</span>;
          }

        return (
        <div className="col">
          
          {this.state.items.map((item) => (
            <Media key={item.id} className="mb-4">
                {/* <img  width={24}  height={24} className="mr-3" src={icondefault}  alt="news" /> */}
                <Media.Body>  
                    <Badge variant="primary">{item.date}</Badge>
                    <p className="m-0 p-0">{item.teaser}
                    {/* <Link className="nav-link" onClick={this.handleShow} to="#" >подробнее</Link> */}
                    </p>
                </Media.Body>
            </Media>
          
          ))}        



</div>
        
            
        );
    }
}

export default NewsTeaser
