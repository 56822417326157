import React, { Component } from 'react'
import { Container, Row, Col, Tabs, Tab  } from 'react-bootstrap' 
import Supplier from './Supplier'
import Buer from './Buer'
import Integration from './Integration'
import {Title} from 'react-doc-title'


export class Doc extends Component {
    render() {
        return (
<React.Fragment>
                <Title string="Документая, руководства по работе и интеграции в Трейд45" />

          <Container >  
            <Row className="pt-5 pb-5">  
                <Col md={12}>
                <h1 className="display-1">Документация</h1>
<Tabs defaultActiveKey="Buer"   id="uncontrolled-tab-example">
  <Tab eventKey="Supplier" title="Для поставщика"><div className="mt-3"><Supplier  /></div></Tab>
  <Tab eventKey="Buer" title="Для покупателя"> <Buer /></Tab>
  <Tab eventKey="integration" title="Интеграция и автоматизация"> <Integration /></Tab>
</Tabs>
                </Col>
            </Row>
          </Container>
          </React.Fragment>
        )
    }
}

export default Doc
