import React, { Component } from 'react'
import { Container, Row, Col  } from 'react-bootstrap'


export class Supplier extends Component {
    render() {
        return (
            <Container >  
            <Row>  
                <Col md={12}>
                <p className="lead">Идет наполнение раздела</p>

                {/* <h2 className="heading">Согласование между подразделениями</h2> 
                <p>Согласование заказа при формировании удаленными подразделениями или офисами позволяет контролировать 
                  необоснованные закупки и оптимизирует бюджет организации
                </p>

                <h2 className="heading">Уведомления на каждой стадии</h2> 
                <p>Каждая стадия заказа проходит через систему уведомлений. Вы знаете на какой стадии находится заказ</p>

                <h2 className="heading">Электронный обмен</h2> 
                <p>Электронный обмен документами - способ быстрого формирования документов прихода в учетной системе</p>


                <h2 className="heading">Автоматизация бизнес процессов</h2> 
                <p>Автоматизация процессов внутри предприятия с учетом особенностей работы с клиентом максимально устранит все недопонимания 
                  между участниками и придаст прозрачности сделки
                </p>

                <h2 className="heading">публикация прайс-листов</h2> 
                <p>Предоставте своим клиентам индивидуальные и общие прайс-листы. Наначайте их каждому контрагенту и 
                </p> */}
                </Col>
                </Row>
            </Container>
        )
    }
}

export default Supplier
