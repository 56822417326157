import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-doc-title'
import App from "./components/App.js";
import Favicon from 'react-favicon';
import Icon from './utils/img/favicon.png'
import SEO from 'react-seo-component'




const app=(
        
<BrowserRouter>
<Provider title="Trade45" >
    <Favicon url={Icon} />
    <App />
 </Provider>
</BrowserRouter>
 )
 
 ReactDOM.render(app,
     document.querySelector("#root")
 );
