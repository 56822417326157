import React from 'react'
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Carousel  } from 'react-bootstrap'; 
import { Parallax } from 'react-parallax';
import imgLaptop from './img/5.png'
import imgLaptopS from './img/laptop2.png'
import imgLaptop4 from './img/laptop4.png'



const HomeSlider = ({t}) => {    
        return (
            <div>
                <Carousel>
  <Carousel.Item>
  <Parallax
                    strength={-100}
                    renderLayer={percentage => (
                        <div
                            style={{
                                position: 'absolute',
                                background: `rgba(228, 228, 228, 0.4)`,
                                right: '0%',
                                top: '0%',
                                width: percentage * 10000,
                                height: percentage * 1000,
                            }}
                        />
                    )}
                >
                    <Container id="farme_1">
                        <Row className="d-flex h-100 d-table flex-wrap">
                            <Col xs={0} md={6} lg={6}></Col>
                            <Col xs={12} md={12} lg={6} className="pb-1">
                                <div className="d-table-cell pt-5 align-middle">
                                    <h2 className="display-2  pb-2">{t('HomeSlider_1.title')}</h2>
                                    <blockquote className="pl-4 blockquote">
                                        <p className='lead'>{t('HomeSlider_1.text')}</p>
                                        <p className="blockquote-footer">{t('HomeSlider_1.blockquote')} </p>
                                    </blockquote>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="d-none d-lg-block position-absolute botom-0 left-0"><img src={imgLaptop} alt="" /></div>
                </Parallax>
  </Carousel.Item>
  

  <Carousel.Item className="bg-light">
                    <Container id="farme_1">
                        <Row className="d-flex h-100 d-table flex-wrap">
                            <Col xs={0} md={6} lg={6}></Col>
                            <Col xs={12} md={12} lg={6} className="pb-1">
                                <div className="d-table-cell pl-4 pt-5 align-middle">
                                    <h2 className="display-2  pb-2">Обработка документов со скоростью секунды</h2>
                                    <blockquote className="pl-4 blockquote ">
                                        <p className='lead'>Простые форматы файлов обмена или API</p>
                                        <p className="blockquote-footer"> позволяют интегрироваться  практически с любой учетной системой и максимально сократить время обработки заказов или поступлений</p>
                                    </blockquote>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="d-none d-lg-block position-absolute botom-0 left-0"><img src={imgLaptopS} alt="" /></div>
  </Carousel.Item>

  <Carousel.Item className="bg-light">
                    <Container id="farme_1">
                        <Row className="d-flex h-100 d-table flex-wrap">
                            <Col xs={0} md={6} lg={6}></Col>
                            <Col xs={12} md={12} lg={6} className="pb-1">
                                <div className="d-table-cell pl-4 pt-5 align-middle">
                                    <h2 className="display-2 pl-4 pb-4">Прайс-листы как инструмент отношений</h2>
                                    <blockquote className="blockquote ">
                                        <p className='lead'>Покажите свою номенклатуру для всех или отдельно для каждого. </p>
                                        <p className="blockquote-footer">управляйте прайс-листами и ценообразованием, расположите к себе клиента персональными предложениями.</p>
                                    </blockquote>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div className="d-none d-lg-block position-absolute botom-0 left-0"><img src={imgLaptop4} alt="" /></div>
  </Carousel.Item>

</Carousel>
            </div>
        )
    }


export default withTranslation()(HomeSlider)
