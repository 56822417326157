import React, { Component } from 'react'
import { Container, Row, Col,Media  } from 'react-bootstrap'
export class Integration extends Component {
    render() {
        return (
            <Container>
                    <Row>
                    <Col md={4}>
                      <ol>
                        <li><span className="display-4">Бизнес-процессы</span>
                          <li>Схема работы</li>
                        </li>
                        <li><span className="display-4">Форматы файлов обмена</span>
                            <li>Типы файлов</li>
                            <li>Описание формата</li>
                            <li>Процесс обмена файлами</li>
                        </li>
                        <li><span className="display-4">Работа с заказами</span>
                            <li>Рабочие панели
                                <li>Панель "Отправленные"</li>
                                <li>Панель "В работе"</li>
                                <li>Панель "Исполненные"</li>
                            </li>
                        </li>
                      </ol>
                    </Col>
                       <Col md={8}>
                       <h2 className="display-3 mt-4">Доступ к сервису</h2>
                       <p >Идет наполнение раздела 2</p>
                       <p >Идет наполнение раздела 2</p>
                       {/* <Media>
                       <a href="#" onClick={this.openModal}>
  <img
    width={350}
    height={200}
    className="mr-3"
    src={Search}
    alt="Generic placeholder"
  />
 </a>
  <Media.Body>
    <h3 className="display-3 mt-4">Поиск и заказ</h3>
    <p>Поиск по фрагменту с уточнение данных позволяет быстро соориентироваться и найти необходимую номенклатуру. 
                  Возможность искать по категориям даст наилучший и быстрый результат.
                </p>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='L61p2uyiMSo' onClose={() => this.setState({isOpen: false})} />
       

  </Media.Body>
</Media> */}
                </Col> 
                </Row>
                </Container>
        
        )
    }
}

export default Integration
