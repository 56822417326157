import React, { Suspense } from "react";
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import '../utils/_custom.scss';

import Header from './Header/Header'
import Footer from './Footer/Footer'

import Home from "./Home/Home";
import Project from "./Project/Project"
import Connection from "./Сonnection/Connection"
import Doc from "./Doc/Doc"
import Contacts from './Contacts/Contacts'
// import News from './News/News'
// import Abouts from './Abouts/Abouts'
import Examples from './Examples/Examples'
// import Tariff from './Tariff/Tariff'
import NotFound from "./NoFound/NotFound"






const App = () => (    
         <BrowserRouter>  
             <Suspense fallback={null}>
                <Header />
                
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route  path="/project" component={Project} />
                    <Route  path="/connection" component={Connection} />
                    <Route  path="/documentation" component={Doc} />
                    <Route  path="/contacts" component={Contacts} />
                    <Route  path="/examples" component={Examples} />
                    <Route path="*" component={NotFound} />
                    <Route path="/404" component={NotFound} />
                    <Redirect to="/404" />
                    
                </Switch>
                <Footer />
                </Suspense>
            </BrowserRouter>
            
        );
    
export default App; 
