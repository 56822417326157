import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, Card  } from 'react-bootstrap' 

export class ContactForm extends Component {
  constructor () {
    super();
      this.state = {
        value: ''
    };
    
    
  }
  
  handleSend(){
    // this.setState({ showModal: true });
    console.log("rkbr");
  }

  render() {
    return (
      <div>
<Container>
<Row> 
<Col className="">
<Card >
  <Card.Body>
    <Card.Title><h3 className="display-3  pb-4">Заявка на обратный звонок</h3></Card.Title>
    
    
    <Form>
    <Form.Group as={Row} controlId="formPlaintextName">
       <Form.Label column sm="4">Ваше имя </Form.Label> <Col sm="8"> <Form.Control type="text" size="lg" placeholder="Имя" />  </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formPlaintextName">
       <Form.Label column sm="4">Мобильный телефон: *</Form.Label> <Col sm="8"> <Form.Control type="text" size="lg" placeholder="Мобильный телефон" />  </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formPlaintextName">
       <Form.Label column sm="4">Электронная почта </Form.Label> <Col sm="8"> <Form.Control type="email" size="lg" placeholder="Электронная почта" />  </Col>
    </Form.Group>

    <Form.Group as={Row} controlId="formPlaintextName">
       <Form.Label column sm="4">Комментарий </Form.Label> <Col sm="8"> <Form.Control as="textarea" size="lg" rows="3" />  </Col>
    </Form.Group>
  
</Form>

    
    
  </Card.Body>
  <Card.Footer> <Button variant="primary" size="lg" onClick={this.handleSend}>Заказать звонок</Button> </Card.Footer>
</Card>
</Col>
</Row > 
</Container >

      </div>
    )
  }
}

export default ContactForm
