import React, { Component } from 'react'

export class zvonk extends Component {
    render() {
        return (
            <div className="ml-1">
                <h4 className="display-4">+7 (495) 145-4018</h4>
            </div>
        )
    }
}

export default zvonk
