import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap' 
import {Title} from 'react-doc-title'

export class Connection extends Component {
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Title string={t('PageTitle.ConnectionTitleDesc')} />
            <Container >
                    <Row className="pt-5 pb-5"> 
                        <Col>
                        <h1 className="display-1">Подключение</h1>
                        <p className="lead">
                            Подключиться к сервису может любое юридическое лицо.
                        </p>
                        </Col>
                        </Row>
                        <Row className="pt-5 pb-5"> 
                        <Col sm={4}>
                        <h2 className="display-4">Шаг 1 </h2>
                        <p className="lead">Выберите направление.</p>
                        </Col>
                        <Col sm={4}>
                        <h2 className="display-4">Шаг 2 </h2>
                        <p className="lead">Зарегистрируйтесь в системе или отправте заявку на подключение.</p>
                        </Col>
                        <Col sm={4}>
                        <h2 className="display-4">Шаг 3 </h2>
                        <p className="lead">Настройте систему под свои нужды.</p>
                        </Col>


                    </Row>
                </Container>
                </React.Fragment>
        )
    }
}

export default withTranslation()(Connection)
