import React from 'react';
import { Container, Row, Col, Form, Button, Modal  } from 'react-bootstrap' 
import {Title} from 'react-doc-title'
import { withTranslation } from 'react-i18next';


function Examples({ t, i18n }) {
    return (

<>

<React.Fragment>
                <Title string={t('PageTitle.ExampleTitle')} />
            <Container >
                    <Row className="pt-5 pb-5"> 
                        <Col>
                        <h1 className="display-1">{t('PageTitle.ExampleTitle')}</h1>
                        <p className="lead">
                            Подключиться к сервису может любое юридическое лицо.
                        </p>
                    
                        </Col>
                    </Row>
                </Container>
                </React.Fragment>
       
      </>
    );
  }
  export default withTranslation()(Examples); 