import React, { Component } from 'react'
import '../../i18n.js'
import { NavLink } from 'react-router-dom'
import Logo from "./img/logo.svg"
import { Navbar, Nav } from 'react-bootstrap'
import MenuLinks from './MenuLinks'
import Zvonk from './zvonk'
import LanguageSelector from '../../utils/LanguageSelector'



export class Header extends Component {
  render() {
        return (
    <header className="sticky-top bg-white">
        <div className="container">
          <Navbar  expand="lg" >
            <NavLink className="navbar-brand" to="/"><img className="invisible-sm" src={Logo} alt="Trade45" height="60px" /></NavLink>
            <Nav className="mr-auto"><Zvonk /></Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
           
            </Nav>
            <Nav className="ml-auto"><MenuLinks /></Nav>
            <Nav className="ml-auto"><LanguageSelector/></Nav>
            </Navbar.Collapse>
        </Navbar>
        </div>
        
      </header>
        )
    }
}

export default Header
