import React from 'react'
import { useTranslation } from 'react-i18next'

const LanguageSelector = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <div className="ChangeLanguage" onChange={changeLanguage}>
      <div className="selector"><input type="radio" value="ru" name="language" defaultChecked /><span>Ru</span></div>
      <div className="selector"><input type="radio" value="en" name="language"  /><span>En</span></div>
      
    </div>
  )
}

export default LanguageSelector