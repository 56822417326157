import React, { Component } from 'react'
import { Container, Row, Col,Media  } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import Search from './img/laptop_3.png'



export class Buer extends Component {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }

      openModal () {
        this.setState({isOpen: true})
      }
    render() {
        return (

                <Container>
                    <Row>
                    <Col md={4}>
                      <ol>
                        <li><span className="display-4">Доступ к сервису</span>
                          <li>Регистрация</li>
                          <li>Подача заявки</li>
                          <li>Вход в сервис</li>
                          <li>Восстановление доступа</li>
                        </li>
                        <li><span className="display-4">Рабочий стол</span>
                            <li>Панель заказов</li>
                            <li>Панель сообщений</li>
                            <li>Панель "Дорожная карта"</li>
                        </li>
                        <li><span className="display-4">Работа с заказами</span>
                            <li>Рабочие панели
                                <li>Панель "Отправленные"</li>
                                <li>Панель "В работе"</li>
                                <li>Панель "Исполненные"</li>
                            </li>
                            <li>Создание заказа
                              <li>Поиск номенклатуры</li>
                              <li>Просмотр по категориям</li>
                              <li>Просмотр по прайс-листам</li>
                              <li>Добавление позиции в корзину</li>
                              <li>Корзина</li>
                              <li>Корзина. Редактирование</li>
                              <li>Сохранение заказа</li>
                              <li>Редактирование заказа</li>
                              <li>Отправка заказа поставщикам</li>
                            </li>
                            <li><span className="display-4">Рекламации</span>
                              <li>Создание рекламации</li>
                              <li>Получение ответа</li>
                            </li>
                            <li><span className="display-4">Электронные документы</span>
                                <li>Типы электронных докуметов</li>
                                <li>Получение и просмотр электронных документов</li>
                                <li>Поиск и загрузка документов качества</li>
                              </li>
                        </li>
                        <li><span className="display-4">Режимы работы </span>
                                <li>Обычный режим</li>
                                <li>Режим согласования</li>
                                <li>Мульти режим</li>
                              </li>

                              <li><span className="display-4">Отчеты и статистика</span>
                                <li>Просмотр отчетов </li>
                                <li>Автоматическая отправка отчетов </li>
                                <li>Отчет по подразделениям</li>
                                <li>Отчет по категориям </li>
                              </li>

                              <li><span className="display-4">Работа с подразделениями</span>
                                <li>Типы участников в системе</li>
                                <li>Добавление  менеджера / подразделения</li>
                                <li>Редактирование менеджера / подразделения</li>
                                <li>Настройка привязок </li>
                                <li>Блокирование доступа менеджера</li>
                              </li>
                              <li><span className="display-4">Работа с Юр лицами</span>
                                <li>Добавление  Юр лица</li>
                              </li>
                              <li><span className="display-4">Работа с Адресами</span>
                                <li>Добавление адресов</li>
                              </li>
                              <li><span className="display-4">Уведомелния</span>
                                <li>Типы уведомлений</li>
                                <li>Работа с уведомлениями</li>
                                <li>Настройка уведомлений</li>
                              </li>

                      </ol>
                    </Col>
                       <Col md={8}>
                       <h2 className="display-3 mt-4">Доступ к сервису</h2>
                       <p>Идет наполнение раздела 2</p>
                       <p>Идет наполнение раздела 2</p>
                       {/* <Media>
                       <a href="#" onClick={this.openModal}>
  <img
    width={350}
    height={200}
    className="mr-3"
    src={Search}
    alt="Generic placeholder"
  />
 </a>
  <Media.Body>
    <h3 className="display-3 mt-4">Поиск и заказ</h3>
    <p>Поиск по фрагменту с уточнение данных позволяет быстро соориентироваться и найти необходимую номенклатуру.
                  Возможность искать по категориям даст наилучший и быстрый результат.
                </p>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='L61p2uyiMSo' onClose={() => this.setState({isOpen: false})} />


  </Media.Body>
</Media> */}
                </Col>
                </Row>
                </Container>
        )
    }
}

export default Buer
