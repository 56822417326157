import React, { Component } from 'react'
import ReactModal from 'react-modal';
import { withTranslation } from 'react-i18next';
import iphone from './img/icon_phone.png'
import ichat from './img/icon_chat.png'
import izvonok from './img/icon_zvonok.png'
import close from './img/close.svg'
import { Container, Row, Col, Form, Button, Card  } from 'react-bootstrap' 
import { YMaps, Map,Placemark } from 'react-yandex-maps'
import FormContact from './ContactForm'
import {Title} from 'react-doc-title'





export class Contacts extends Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  render() {
    const {t} =this.props;
      return (
            <React.Fragment>
                <Title string={t('PageTitle.ContactTitleDesc')} />
            <div className="d-flex bg-light">    
            <Container  className="text-center ">
            <h2 className="display-1 mt-4">{t('PageTitle.ContactTitle')}</h2>
                  <Row className="pt-4 pb-4"> 
                    <Col lg={4}>
                      {/*
                      <img src={ichat} alt="" />
                      <p className="display-4 text-black-50">Задать вопрос</p>
                      <small className=" text-black-50">он-лайн консультант</small>
                      */}

                    </Col>
                    <Col lg={4}>
                      <img src={iphone} alt="" />
                      <p className="display-3 text-black-50">+7 (495) 145-4018</p>
                      <small className="text-black-50">по всем вопросам</small>
                    </Col>
                    <Col lg={4}>
                      {/*
                    <img src={izvonok} alt="" />
                      <p className="display-4 text-black-50"><a href="#" onClick={this.handleOpenModal} >Заказать звонок</a></p>
                      <small className=" text-black-50">мы перезвоним в ближайшее время</small>
                      */}
                    </Col>
                  </Row > 
            </Container >
          </div>

          
<ReactModal 
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          className="Modal "
          ariaHideApp={false}
          overlayClassName="Overlay "
        >
          <span onClick={this.handleCloseModal}><img src={close} className="closebutton" alt="close" /></span>


<FormContact />
        
</ReactModal>

            <Container >
              <Row className="text-center">
              <Col >

                    <h2 className="display-3 pt-5">{t('ContactPage.Office')}</h2>
            </Col>
            </Row>
                    <Row className="pt-5 pb-5"> 

                        <Col md={5}>
                        <h3>м. Южная</h3>
                        <p className="lead">г. Москва, Варшавское шоссе д. 125Ж, стр. 3<br/>
                        Время работы: с 9:00 до 18:00, пн-пт <br/>
                        info@trade45.ru
                        </p>
                        </Col>
                        <Col md={7}>
                        <YMaps>
    <div>
      <Map  className="Ymaps" defaultState={{ center: [55.623676, 37.617303], zoom: 15 }} >
      <Placemark 
        geometry={[55.623676, 37.617303] }
        options={{iconLayout: 'default#image', iconImageSize: [160, 80], iconImageHref: 'img/baloon.svg',}}
      />
      </Map>
    </div>

  </YMaps>
                        </Col>
                    </Row>
                </Container>



                </React.Fragment>
        )
    }
  }

export default withTranslation()(Contacts)
