import React from 'react'
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap'
import HomeSlider from './HomeSlider'
import NewsTeaser from '../News/NewsTeaser'
import imgmiddle from './img/middle.png'
import HomeThumbCarusel from './HomeThumbCarusel'
import {Title} from 'react-doc-title'






const Home = ({t}) => {
        return (
            <main>
          <Title string={t('PageTitle.HomeTitleDesc')} />

                <HomeSlider />

                

                <div id="frame_0">
                    <Container className="pt-5 pb-5">
                        <Row className="pt-2">
                            <Col xs={12} md={12} className="text-center">
                                <h3 className="display-2">{t('HomeFrame_0.title')}</h3>
                                <p className="lead mb-5">{t('HomeFrame_0.text')}</p>
                                <img className="img-fluid" src={imgmiddle} alt="функции" />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <div className="bd-callout bd-callout-warning">
                                    предоставляет каждому участнику <span className="display-4">удобные инструменты</span> и сервисы для работы с <span className="display-3">большим ассортиментом</span> различнной номенклатуры.
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="bd-callout bd-callout-warning">
                                    <span className="display-3">максимально</span> автоматизировать процессы связанные с <span className="display-4"> ручной работой</span> менеджеров и дать им возможноть заняться <span className="display-3">своей</span> непосредственной работой.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>


        <div id="farme_5">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col md={12} className="text-center">
                            <h2 className="display-2">Для кого предназначен</h2>
                            <p className="lead p-4"> Сервис хорошо подходит для малого и среднего бизнеса в сферах закупок и продаж</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="text-center">
                            <h3 className="display-3">Поставки</h3>
                            <p className="lead p-4">Вы предоставляете своим клиентам свой ассортимент продукции и автоматизируете бизнес-процессы обработки заказов и документов. </p>
   
                            
                        </Col>
                        <Col md={6} className="text-center">
                            <h3 className="display-3">Закупки </h3>
                            <p className="lead p-4"> Вы видите номенклатуру и цены всех поставщиков в одном месте. Контролируете свои торговые точки, анализируете рынки.
                            </p>
                            
                        </Col>
                    </Row>
                </Container>
</div>
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col md={12} className="text-center">
                            <h2 className="display-2">Действующие отрасли</h2>
                            <p className="lead p-4">Медицина. Расходные мадицинские материалы. Авто. Запчасти и акссесуары</p>
                        </Col>
                    </Row>
                </Container>


                <div id="farme_2" className="d-lg-flex position-relative">
                <Container className="pt-5 pb-5">
                        <Row>
                            <Col md={12} className="text-center">
                                <h2 className="display-2">В каких отраслях работатет</h2>
                                <p className="lead p-4"> Сервис может работать в любой отрасли, но приемущетвенно адаптирован для высокоассортиментого товарного ряда. В отраслях, где существует необходимость
                                быстро найти и заказать, получить все документы, в том числе докумены качества. Хорошо подходит для многофилиальных подраздделений
                    где необходим процесс контроля закупок или согласования. </p>
                            </Col>
                        </Row>
                    </Container>
                </div>




                <Container className="pt-5 pb-5">
                    <Row >
                        <Col md={5}>
                            <h2 className="display-3 pb-3">{t('News.Header')}</h2>
                            <NewsTeaser />
                        </Col>
                        <Col md={7}>
                            <h2 className="display-3 pb-3 text-center">Консультации по внедрению</h2>
                            <h2 className="display-3 text-center text-primary" > +7 (495) 145-4018</h2>
                        </Col>
                    </Row>
                </Container>

                <Container className="pt-5 pb-5">
                    <Row >
                        <Col md={12}><h1 className="display-3 text-center mt-5">Систему используют</h1></Col>
                    </Row>
                </Container>
                <HomeThumbCarusel />


            </main>

        )
    }


export default withTranslation()(Home)
