import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Container, Row, Col  } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import Logo from "./img/logo.svg"


  const Footer=({t})=>{
        return (
<footer>
<Container className="py-5">
  <Row >
    <Col className="col-12 col-md" >
    <img src={Logo} alt="" width="100" />
      <small className="d-block mb-3 text-muted">© 2012-2020</small>
    </Col>
    <Col className="col-6 col-md" >
      <h5>{t('Footer.TiteFeatures')}</h5>
      <ul className="list-unstyled text-small">
        <li><Link className="text-muted" to="/project#bayer" >{t('Footer.BuerLink')}</Link></li>
        <li><Link className="text-muted" to="/project#supplier">{t('Footer.SaleLink')}</Link></li>
        <li><Link className="text-muted" to="/project#partners" >{t('Footer.PartnerLink')}</Link></li>
      </ul>
    </Col>
    <Col className="col-6 col-md" >
      <h5>{t('Footer.TiteResources')}</h5>
      <ul className="list-unstyled text-small">
      <li><NavLink activeClassName="active" className="text-muted" to="/connection" >{t('Footer.ConnectionLink')}</NavLink> </li>
      {/* <li><NavLink activeClassName="active" className="text-muted" to="/examples" >Примеры</NavLink> </li> */}
      {/* <li><NavLink activeClassName="active" className="text-muted" to="tariff" >Тарифы</NavLink> </li> */}
        
      </ul>
    </Col>
    <Col className="col-12 col-md" >
     {/* <h5>{t('Footer.TiteDocumentation')}</h5>
      <ul className="list-unstyled text-small">
      <li><Link className="text-muted" to="/documentation#buyer" >{t('Footer.WorkBuerLink')}</Link></li>
      <li><Link className="text-muted" to="/documentation#supplier" >{t('Footer.WorkSalerLink')}</Link></li>
         <li><Link className="text-muted" to="/documentation#developer" >Для разработчиков</Link></li> 
      </ul>*/}
    </Col>
    <Col className="col-12 col-md" >
      <h5>{t('Footer.TiteAbout')}</h5>
      <ul className="list-unstyled text-small">
       {/* <li><Link className="text-muted" to="/abouts#rules" >Правила использования</Link></li> */}
       {/* <li><Link className="text-muted" to="/abouts#users" >Пользовательское соглашение</Link></li> */}
       <li><NavLink activeClassName="active" className="text-muted" to="/contacts">{t('Footer.ContactLink')}</NavLink> </li>
        
      </ul>
    </Col>
  </Row>
</Container>
</footer>
            
        )
    }


export default withTranslation()(Footer)
