import React, { Component } from 'react'
import { Container, Row, Col, Media } from 'react-bootstrap' 
import { withTranslation } from 'react-i18next';
import img2 from './img/2.jpg'
import { Parallax } from 'react-parallax'
import {Title} from 'react-doc-title'
import i_featured_delivery from './img/i_featured_delivery.svg'
import i_featured_uri from './img/i_featured_uri.svg'
import i_featured_online from './img/i_featured_online.svg'
import i_featured_order from './img/i_featured_order.svg'
import i_featured_contact from './img/i_featured_contact.svg'
import i_featured_doc from './img/i_featured_doc.svg'
import i_featured_price from './img/i_featured_price.svg'
import i_featured_info from './img/i_featured_info.svg'
import i_featured_auto from './img/i_featured_auto.svg'
import i_featured_history from './img/i_featured_history.svg'
import i_featured_concord from './img/i_featured_concord.svg'
import i_featured_client from './img/i_featured_client.svg'
import i_featured_analytic from './img/i_featured_analytic.svg'
import i_featured_1с from './img/i_featured_1с.svg'







export class Project extends Component {

  

    render() {
      const { t } = this.props;
        return (
        <div>
    <Title string={t('PageTitle.ProgectTitleDesc')} />


            <Container>
                <Row>
                <Col md={5}><h2 className="display-1">О проекте</h2></Col>
                    <Col md={7}><p>Сервис выступает в качестве рынка спроса и предложений, в котором участвуют покупатель и поставщик. </p>
                    <p>Создает бизнес-среду для обмена информацией межуд участниками процесса и позволяет максимально автоматизировать 
                      рутинные работы, связанные с обработкой большого количества различной номенклатуры.
                    </p>
                    </Col>
                    
                </Row>
            </Container>
        

<Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={img2}
            bgImageAlt="the cat"
            strength={300}
        >
<Container id="bayer" className="p-5 mb-3">
  <Row>
      <Col>
       <h2 className="display-3" >Отличия и преимущества для покупателя</h2>
      </Col>
      </Row>
      </Container>          
 </Parallax>

<Container id="bayer">
<Row >
<Col md={6} className="p-3">
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_online}  alt="OnLine" />
    <Media.Body>
      <h5>OnLine 24</h5>
      <p>Нет необходимости устанавливать дополнительное ПО, что позволяет снять ограничения по региональному и временному признаку. 
        Кдинственное условие - это любое устройство с доступом в интернет.
      </p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_order}  alt="Быстрый заказ" />
    <Media.Body>
      <h5>Быстрый поиск и заказ</h5>
      <p>Удобный и быстрый иструмент поиска и заказа, позволяет быстро найти и заказать нужный товар, что сокращает время менеджера по закупкам. 
        Функция повтора заказа на основании уже созданного.
      </p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_uri}  alt="Несколько Юр лиц" />
    <Media.Body>
      <h5>Юридические лица</h5>
      <p>Ведение неограниченного количества юридических лиц в одном профиле.</p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_delivery}  alt="Адреса доставки" />
    <Media.Body>
      <h5>Адреса доставок</h5>
      <p>Ведение неограниченного количества адресов доставок, складов, торговых точек.</p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_concord}  alt="Согласование заявок" />
    <Media.Body>
      <h5>Согласование заказов</h5>
      <p>Подсистема согласованя заказов внутри организации, позволяет осуществлять контроль закупок удаленных подразделений.</p>
    </Media.Body>
  </Media>
</Col>

<Col md={6} className="p-3">
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_price}  alt="Сводный прайс лист" />
    <Media.Body>
      <h5>Сводный прайс лист</h5>
      <p>Прайс-листы поставщиков на одной площадке. Создание персональных предложений для своих клиентов.</p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_history}  alt="История заказов" />
    <Media.Body>
      <h5>История заказов</h5>
      <p>Все изменения храняться в виде таймлайна.</p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_auto}  alt="Автоматизация" />
    <Media.Body>
      <h5>Автоматизация</h5>
      <p>Получение и отправка  электронных документов в нужном формате для врутреннего обмена.</p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_info}  alt="Информирование" />
    <Media.Body>
      <h5>Информирование</h5>
      <p>Уведомления о каждом шаге обработке заказа. </p>
    </Media.Body>
  </Media>
  <Media className="mb-4">
    <img  width={128}  height={128} className="mr-3" src={i_featured_doc}  alt="Документы качества" />
    <Media.Body>
      <h5>Документы качества</h5>
      <p>Поиск и получение документов качества</p>
    </Media.Body>
  </Media>
</Col>                
                
</Row>
</Container>


<Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={img2}
            bgImageAlt="the cat"
            strength={300}
        >
<Container id="bayer" className="p-5 mb-3">
  <Row>
      <Col><h2 className="display-3" >Отличия и преимущества для поставщика</h2></Col>
      </Row>
</Container>          
 </Parallax>


<Container id="supplier">
<Row>
<Col md={6} className="p-3">
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_uri}  alt="Несколько Юр лиц" />
  <Media.Body>
    <h5>Юридические лица</h5>
    <p>Ведение нескольких юридических лиц (многофилиальные сети) </p>
  </Media.Body>
</Media>



<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_price}  alt="Работа с прайс-листами" />
  <Media.Body>
    <h5>Работа с прайс-листами</h5>
    <p>Создание персонализированных прайс-листов. Установка ценообразования. </p>
  </Media.Body>
</Media>
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_contact}  alt="Контакты" />
  <Media.Body>
    <h5>Контакты</h5>
    <p>Полная информация о контактах. Чистые поставщики. Сервис осуществляет контроль на чистоту и компетентность всех участников.</p>
  </Media.Body>
</Media>

<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_1с}  alt="1c" />
  <Media.Body>
    <h5>Интеграции с 1C </h5>
    <p>Решение для интеграции с системой 1с Упраление предприятием</p>
  </Media.Body>
</Media>

</Col>

<Col md={6} className="p-3">
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_auto}  alt="Интеграция" />
  <Media.Body>
    <h5>Интеграция </h5>
    <p>Полная или частичная интеграция с учетными системами</p>
  </Media.Body>
</Media>
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_client}  alt="Расширение клиентской базы" />
  <Media.Body>
    <h5>Увеличение клиентской базы</h5>
    <p>Инструменты увеличения клиентской базы</p>
  </Media.Body>
</Media>
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_doc}  alt="Документы качества" />
  <Media.Body>
    <h5>Документы качества</h5>
    <p>Решение для формирования документов качества. Документы храняться на серверах поставщика. </p>
  </Media.Body>
</Media>
<Media className="mb-4">
  <img  width={128}  height={128} className="mr-3" src={i_featured_analytic}  alt="Аналитика" />
  <Media.Body>
    <h5>Аналитика и отчетность</h5>
    <p>Анализ рынка. Динамика изменения цен. Отчеты по закупкам подразделений</p>
  </Media.Body>
</Media>
</Col>                
</Row>
</Container>


<Parallax
            blur={{ min: -15, max: 15 }}
            bgImage={img2}
            bgImageAlt="the cat"
            strength={300}
        >
<Container id="bayer" className="p-5 mb-3">
  <Row>
  <h2 className="display-3" >Для партнеров</h2>
      </Row>
</Container>          
 </Parallax>


<Container id="partners">
    
     <Row >
      <Col md={4} className="p-3">  
      Мы предлагаем партнерские условия для внедрения в любой подходящей отрасли.
      </Col>
      <Col md={8} className="p-3">  
      
      </Col>
    </Row>
</Container>                

</div>
        )
    }
}
export default withTranslation()(Project)